import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { FortifiedItemData } from "../util/fortified-items";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface MultiSelectProps {
  fortifiedItems: FortifiedItemData[];
  selectedItems: number[];
  handleChange: (selectedItems: number[]) => void;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  selectedItems,
  handleChange,
  fortifiedItems,
}) => {
  const handleInternalChange = (
    event: SelectChangeEvent<typeof selectedItems>
  ) => {
    const {
      target: { value },
    } = event;
    const selectedIds =
      typeof value === "string" ? value.split(",").map(Number) : value;

    const filteredSelectedIds = selectedIds.filter(
      (id) => typeof id === "number" && !isNaN(id)
    );

    handleChange(filteredSelectedIds);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedItems}
          onChange={handleInternalChange}
          input={<OutlinedInput size="small" />}
          renderValue={(selected) =>
            selected.length === 1
              ? fortifiedItems.find(
                  (item) => item.nutrientFactId === selected[0]
                )?.name
              : "Selected Nutrient Facts"
          }
          MenuProps={MenuProps}
        >
          {fortifiedItems.map((item) => (
            <MenuItem key={item.nutrientFactId} value={item.nutrientFactId}>
              <Checkbox
                checked={
                  item.nutrientFactId !== undefined &&
                  selectedItems.indexOf(item.nutrientFactId) !== -1
                }
              />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultiSelect;
