import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Controller, useForm } from "react-hook-form";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ItemCollection } from "./ItemCollection";
import { components } from "../data/items-v2.types";
import { useAxios } from "../axios-provider";
import { useMutation } from "@tanstack/react-query";
import { saveItemMarketingInformation, saveIsFortifiedApiRequest } from "../data/items";
import { RoleContext } from "../role-provider";
import {
  ApiError,
  DropDownList,
  Footer,
  SANITIZE_OPTS_ALLOW_TAGS,
  SANITIZE_OPTS_NO_TAGS,
  ValidItemAttribute,
  ValidItemCollectionItem,
} from "../utils";
import { Loader } from "./loader/Loader";
import { LanguageSelector } from "./LanguageSelector";
import { useNavigate } from "react-router-dom";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import { UnsavedChangesModal } from "./UnsavedChangesModal";
import { ManageFootersModal } from "./ManageFootersModal";
import DOMPurify from "dompurify";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";
import { getCustomFooters } from "../data/miscellaneous";
import { useCustomQuery } from "../hooks/use-custom-query";

type DropDownItem = components["schemas"]["DropDownItem"];

type ItemAttributeList = {
  item_id: string;
  item_type: string;
  time_of_day: string;
  item_name: string;
  item_marketing_name: string;
  short_name: string;
  genesis_item_name: string;
  menu_item_no: string;
  do_not_show: string;
  serves: string;
  item_name_special_symbols: string;
  description: string;
  item_comments: string;
  keywords: string;
  item_ingredient_statement: string;
  item_additional_text_ingredient_statement: string;
  item_allergen: string;
  item_additional_allergen: string;
  text: string;
  item_meta_title: string;
  item_meta_description: string;
  customization_label: string;
  customization_button: string;
  show_product_images: string;
  item_status_flag: string;
  specialization_text1: string;
  specialization_text2: string;
  sitemap: string;
  frequency: string;
  priority: string;
  attach_item_thumbnail_image: string;
  attach_item_hero_image: string;
  attach_transparent_icon_image: string;
  attach_meal_bundle_image: string;
  has_components: string;
  footer: string;
  logo_1: string;
  logo_2: string;
  logo_3: string;
  genesis_menu_item_no: string;
  imported_item_name: string;
  import_id: string;
  pim_id: string;
  pim_item_name: string;
  is_fortified_menu_item: string;
};
type ItemFocusableInputFields = {
  description: string;
  item_comments: string;
  keywords: string;
  item_ingredient_statement: string;
  item_additional_text_ingredient_statement: string;
  item_allergen: string;
  item_additional_allergen: string;
  text: string;
  item_meta_description: string;
};

type ItemMarketingFormData = {
  attributeList: ItemAttributeList;
  footerList: Footer[];
  itemCollectionList: ValidItemCollectionItem[];
};
type FooterType = {
  footerText: string;
  footerId: number;
};
type ValidItemAttributeList = {
  keyName?: string;
  isEditable?: string;
};
type ItemMarketingFormProps = {
  itemId?: number;
  editable: boolean;
  isLive: boolean;
  attributes: ValidItemAttribute[];
  collectionItems: ValidItemCollectionItem[];
  dropDownList: DropDownList;
  footers: Footer[];
  saveSuccessMessage: string;
  language: string;
  setLanguage: (language: string) => void;
  onSaveSuccess?: () => void;
};
export const ItemMarketingForm = ({
  itemId,
  editable,
  isLive,
  attributes,
  collectionItems,
  dropDownList,
  footers,
  saveSuccessMessage,
  language,
  setLanguage,
  onSaveSuccess,
}: ItemMarketingFormProps) => {
  const { selectedCountry, selectedRole, isReaderRole, isLegalRole } =
    useContext(RoleContext);
  const navigate = useNavigate();
  const { apiClient } = useAxios();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [legalSuffix, setLegalSuffix] = useState<string | null>(null);
  const [isFooterModalOpen, setIsFooterModalOpen] = useState<boolean>(false);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);

  const handleSaveSuccess = () => {
    setSuccessMessage(null);
    if (typeof onSaveSuccess === "function") {
      onSaveSuccess();
    }
  };

  const getDropDownValuesByKeyName = useCallback(
    (keyName: string): DropDownItem[] => {
      if (!dropDownList[keyName]) {
        return [];
      }
      return dropDownList[keyName];
    },
    [dropDownList]
  );

  const getAttributeByKeyName = useCallback(
    (keyName: string): ValidItemAttribute | undefined => {
      return attributes.find((attr) => attr.keyName === keyName);
    },
    [attributes]
  );
// Varshitha commented for PLATFORM-8781
  // const itemIdAttribute = useMemo(() => {
  //   return getAttributeByKeyName("item_id");
  // }, [getAttributeByKeyName]);

  const allAttributeKeyNames = useMemo(() => {
    return attributes.map((attr) => attr.keyName);
  }, [attributes]);
  const attributesMap = useMemo(() => {
    const attributes: Partial<{ [key in keyof ItemAttributeList]: any }> = {};

    allAttributeKeyNames.forEach((key) => {
      attributes[key as keyof ItemAttributeList] = getAttributeByKeyName(key);
    });

    return attributes;
  }, [allAttributeKeyNames, getAttributeByKeyName]);
  const itemIdAttribute = attributesMap["item_id"];
  const itemTypeAttribute = attributesMap["item_type"];
  const timeOfDayAttribute = attributesMap["time_of_day"];
  const itemNameAttribute = attributesMap["item_name"];
  const marketingNameAttribute = attributesMap["item_marketing_name"];
  const shortNameAttribute = attributesMap["short_name"];
  const genesisItemNameAttribute = attributesMap["genesis_item_name"];
  const menuItemNumberAttribute = attributesMap["menu_item_no"];
  const doNotShowAttribute = attributesMap["do_not_show"];
  const servesAttribute = attributesMap["serves"];
  const descriptionAttribute = attributesMap["description"];
  const commentsAttribute = attributesMap["item_comments"];
  const keywordsAttribute = attributesMap["keywords"];
  const ingredientStatementAttribute = attributesMap["item_ingredient_statement"];
  const additionalTextIngredientStatementAttribute = attributesMap["item_additional_text_ingredient_statement"];
  const additionalAllergenAttribute = attributesMap["item_additional_allergen"];
  const allergenAttribute = attributesMap["item_allergen"];
  const textAttribute = attributesMap["text"];
  const metaTitleAttribute = attributesMap["item_meta_title"];
  const customizationLabelAttribute = attributesMap["customization_label"];
  const metaDescriptionAttribute = attributesMap["item_meta_description"];
  const customizationButtonAttribute = attributesMap["customization_button"];
  const showProductImagesAttribute = attributesMap["show_product_images"];
  const itemStatusFlagAttribute = attributesMap["item_status_flag"];
  const specializationText1Attribute = attributesMap["specialization_text1"];
  const specializationText2Attribute = attributesMap["specialization_text2"];
  const attachItemThumbnailImageAttribute = attributesMap["attach_item_thumbnail_image"];
  const attachItemHeroImageAttribute = attributesMap["attach_item_hero_image"];
  const attachTransparentIconImageAttribute = attributesMap["attach_transparent_icon_image"];
  const attachMealBundleImageAttribute = attributesMap["attach_meal_bundle_image"];
  const logo1Attribute = attributesMap["logo_1"];
  const logo2Attribute = attributesMap["logo_2"];
  const logo3Attribute = attributesMap["logo_3"];
  const genesisMenuItemNumberAttribute = attributesMap["genesis_menu_item_no"];
  const importedItemNameAttribute = attributesMap["imported_item_name"];
  const importIdAttribute = attributesMap["import_id"];
  const pimIdAttribute = attributesMap["pim_id"];
  const pimItemNameAttribute = attributesMap["pim_item_name"];
  const isFortifiedMenuItemAttribute = attributesMap["is_fortified_menu_item"];
  const siteMapAttribute = attributesMap["sitemap"];

  function isAttributeEditable(attribute: ValidItemAttributeList | undefined): boolean {
    return attribute?.isEditable === "Y" ? false : true;
  }
  const isEditableItemId = isAttributeEditable(itemIdAttribute);
  const isEditableProductType = isAttributeEditable(itemTypeAttribute);
  const isEditableTimeOfDay = isAttributeEditable(timeOfDayAttribute);
  const isEditableItemName = isAttributeEditable(itemNameAttribute);
  const isEditableMarketingName = isAttributeEditable(marketingNameAttribute);
  const isEditableShortName = isAttributeEditable(shortNameAttribute);
  const isEditableGenesisItemName = isAttributeEditable(genesisItemNameAttribute);
  const isEditableMenuItemNumber = isAttributeEditable(menuItemNumberAttribute);
  const isEditableDoNotShow = isAttributeEditable(doNotShowAttribute);
  const isEditableServes = isAttributeEditable(servesAttribute);
  const isEditableDescription = isAttributeEditable(descriptionAttribute);
  const isEditableComments = isAttributeEditable(commentsAttribute);
  const isEditableKeywords = isAttributeEditable(keywordsAttribute);
  const isEditableIngredientStatement = isAttributeEditable(ingredientStatementAttribute);
  const isEditableAdditionalTextIngredientStatement = isAttributeEditable(additionalTextIngredientStatementAttribute);
  const isEditableAdditionalAllergen = isAttributeEditable(additionalAllergenAttribute);
  const isEditableAllergen = isAttributeEditable(allergenAttribute);
  const isEditableText = isAttributeEditable(textAttribute);
  const isEditableMetaTitle = isAttributeEditable(metaTitleAttribute);
  const isEditableCustomizationLabel = isAttributeEditable(customizationLabelAttribute);
  const isEditableMetaDescription = isAttributeEditable(metaDescriptionAttribute);
  const isEditableCustomizationButton = isAttributeEditable(customizationButtonAttribute);
  const isEditableShowProductImages = isAttributeEditable(showProductImagesAttribute);
  const isEditableItemStatusFlag = isAttributeEditable(itemStatusFlagAttribute);
  const isEditableSpecializationText1 = isAttributeEditable(specializationText1Attribute);
  const isEditableSpecializationText2 = isAttributeEditable(specializationText2Attribute);
  const isEditableAttachItemThumbnailImage = isAttributeEditable(attachItemThumbnailImageAttribute);
  const isEditableAttachItemHeroImage = isAttributeEditable(attachItemHeroImageAttribute);
  const isEditableAttachTransparentIconImage = isAttributeEditable(attachTransparentIconImageAttribute);
  const isEditableAttachMealBundleImage = isAttributeEditable(attachMealBundleImageAttribute);
  const isEditableLogo1 = isAttributeEditable(logo1Attribute);
  const isEditableLogo2 = isAttributeEditable(logo2Attribute);
  const isEditableLogo3 = isAttributeEditable(logo3Attribute);
  const isEditableGenesisMenuItemNumber = isAttributeEditable(genesisMenuItemNumberAttribute);
  const isEditableImportedItemName = isAttributeEditable(importedItemNameAttribute);
  const isEditableImportId = isAttributeEditable(importIdAttribute);
  const isEditablePimId = isAttributeEditable(pimIdAttribute);
  const isEditablePimItemName = isAttributeEditable(pimItemNameAttribute);
  const isEditableIsFortifiedMenuItem = isAttributeEditable(isFortifiedMenuItemAttribute);
  const isEditableSiteMap =isAttributeEditable(siteMapAttribute);


  const [focusedFields, setFocusedFields] = useState({
    description: false,
    item_comments: false,
    keywords: false,
    item_ingredient_statement: false,
    item_additional_text_ingredient_statement: false,
    item_allergen: false,
    item_additional_allergen: false,
    text: false,
    item_meta_description: false,
  });
  const handleFieldFocus = (
    fieldName: keyof ItemFocusableInputFields,
    isFocused: boolean
  ) => {
    setFocusedFields((prevFields) => ({
      ...prevFields,
      [fieldName]: isFocused,
    }));
  };
  const getDefaultValues = (
    attributes: ValidItemAttribute[]
  ): ItemAttributeList => {
    const defaultValues: Partial<ItemAttributeList> = {};
    attributes.forEach((attr) => {
       //change added for platform 9440 start amanjare
      switch (attr.keyName) {
        case "do_not_show":
          defaultValues[attr.keyName as keyof ItemAttributeList] = attr?.value && attr.value !== "" ? attr.value : "Do not Show";
          break;
        case "item_status_flag":
          defaultValues[attr.keyName as keyof ItemAttributeList] = attr?.value && attr.value !== "" ? attr.value : "None";
          break;
        case "sitemap":
          defaultValues[attr.keyName as keyof ItemAttributeList] = attr?.value && attr.value !== "" ? attr.value : "No";
          break;
        case  "show_product_images":
          defaultValues[attr.keyName as keyof ItemAttributeList] = attr?.value && attr.value !== "" ? attr.value : "No";
          break;
        case "time_of_day":
          defaultValues[attr.keyName as keyof ItemAttributeList] = attr?.value && attr.value !== "" ? attr.value : "Whole Day";
          break;    
        default:
          defaultValues[attr.keyName as keyof ItemAttributeList] = attr.value ?? "";
          break;

       }
    
        //change added for platform 9440 end amanjare
    
    });
    return defaultValues as ItemAttributeList;
  };

  const {
    register,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    control,
  } = useForm<ItemMarketingFormData>({
    defaultValues: {
      attributeList: getDefaultValues(attributes),
      footerList: footers,
      itemCollectionList: collectionItems,
    },
  });

  const selectedItemType = watch("attributeList.item_type");
  const editableFooters = watch("footerList");
  const itemCollectionItems = watch("itemCollectionList");
  const isFortifiedItem = watch("attributeList.is_fortified_menu_item");
  
  const handleItemCollectionDataChange = (data: ValidItemCollectionItem[]) => {
    const formattedItemCollection = data.map(
      (item: ValidItemCollectionItem, index: number) => ({
        associatedCategoryId: item.associatedCategoryId,
        associatedCategoryName: item.associatedCategoryName || "",
        displayOrder: index + 1,
        isChangeable: item.isChangeable,
        associatedItemId: item.associatedItemId,
        associatedItemName: item.associatedItemName,
      })
    );
    setValue("itemCollectionList", formattedItemCollection, {
      shouldDirty: true,
    });
  };

  const handleRemoveFooter = (footerId: number) => {
    const updatedFooterList = editableFooters.filter(
      (footer) => footer.id !== footerId
    );
    setValue("footerList", updatedFooterList, { shouldDirty: true });
  };

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  useEffect(() => {
    // Whenever attributes change, update the form's default values
    const defaultValues = getDefaultValues(attributes);
    setValue("attributeList", defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes]);

  const handleCancel = () => {
    if (isDirty) {
      setShowUnsavedChangesModal(true);
    } else {
      navigate("/items");
    }
  };
  const handleLeaveNavigation = () => {
    navigate("/items");
  };

  const data = useCustomQuery(
    ["getCustomFooters", { language }],
    () =>
      getCustomFooters(apiClient)({
        countryCode: selectedCountry!,
        languageCode: language!,
      })
  ); 
  
  const notSupportedMessage = data && data.data && data.data.data.message;

  const saveItemMarketingInformationRequest =
    saveItemMarketingInformation(apiClient);
  const { mutate, isLoading } = useMutation(
    (data: ItemMarketingFormData) => {
      const { attributeList, footerList, itemCollectionList } = data;
      const logoList = getDropDownValuesByKeyName("logoList");
      const validLogos = logoList.map((logo) => logo.value);
      const modifiedData = {
        ...attributeList,
        logo_1:
          attributeList.logo_1 !== "No" &&
          !validLogos.includes(attributeList.logo_1)
            ? "No"
            : attributeList.logo_1,
        logo_2:
          attributeList.logo_2 !== "No" &&
          !validLogos.includes(attributeList.logo_2)
            ? "No"
            : attributeList.logo_2,
        logo_3:
          attributeList.logo_3 !== "No" &&
          !validLogos.includes(attributeList.logo_3)
            ? "No"
            : attributeList.logo_3,
      };
      const attributesToSave: ValidItemAttribute[] = [];
      Object.entries(modifiedData).forEach(([key, value]) => {
        if (key !== "item_id") {
          const attr = getAttributeByKeyName(key);
          if (attr) {
            attributesToSave.push({
              ...attr,
              value,
            });
          }
        }
      });

      return saveItemMarketingInformationRequest(
        {
          attributeList: attributesToSave,
          footerList,
          itemCollectionList,
        },
        {
          ...(typeof itemId !== "undefined" ? { itemId } : {}),
          countryCode: selectedCountry!,
          roleId: selectedRole!,
          languageCode: language,
        }
      );
    },
    {
      onMutate: () => saveItemMarketingInformationRequest,
      onSuccess: (responseData) => {
        const responseDataTyped = responseData as { data?: { attributeList?: any[] } };
      
        const attributeListDetails = responseDataTyped.data?.attributeList;
        if (attributeListDetails) {
        const fortifiedMenuItem = attributeListDetails.filter(attribute => attribute.keyName === 'is_fortified_menu_item');
        
        if ( fortifiedMenuItem?.length > 0)  {
          mutateIsFortified(responseData?.data?.itemId || 0);
        } else {
          setSuccessMessage(saveSuccessMessage);
        }
      }
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const saveIsFortifiedApiRequestMap = saveIsFortifiedApiRequest(apiClient);
  const { mutate: mutateIsFortified } = useMutation(
    (_addedItemId: number) => {
      const requestData = {
        item_Id: itemId || _addedItemId,
        is_fortified_menu_item: isFortifiedItem,
      };

      return saveIsFortifiedApiRequestMap(requestData, {
        countryCode: selectedCountry!,
        languageCode: language,
      });
    },
    {
      onMutate: () => saveIsFortifiedApiRequestMap,
      onSuccess: () => {
       setSuccessMessage(saveSuccessMessage);

      },
      onError: (error) => setErrorMessage(String(error)),
    }
  );

  const onSubmit = (formData: ItemMarketingFormData) => {
    const { attributeList, footerList, itemCollectionList } = formData;
    if (!!isLegalRole && legalSuffix) {
      attributeList.item_name += legalSuffix;
    }
    if (typeof itemId === "undefined") {
      attributeList.has_components = "No";
    }

    if (!attributeList.is_fortified_menu_item || attributeList.is_fortified_menu_item.trim() === '') {
      attributeList.is_fortified_menu_item = 'No';
    }
    
    const submissionData = {
      attributeList,
      footerList,
      itemCollectionList,
    };
    mutate(submissionData);
  };
  const renderSimpleField = useCallback(
    (
      fieldName: keyof ItemAttributeList,
      fieldId: string,
      disabled: boolean,
      required: boolean,
      purify: boolean,
      fullWidth?: boolean,
      allowTags: boolean = false
    ) => {
      const colspan = !!fullWidth ? 12 : 6;
      const { attributeList: attributeErrors } = errors;
      const error =
        typeof attributeErrors !== "undefined"
          ? attributeErrors[fieldName]
          : undefined;
      const attr = getAttributeByKeyName(fieldName);
      const fieldLabel = attr?.name ?? "";
      const handleBlur = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        if (!purify) {
          setValue(`attributeList.${fieldName}`, event.target.value);
        } else {
          const sanitizedValue = DOMPurify.sanitize(
            event.target.value,
            !allowTags ? SANITIZE_OPTS_NO_TAGS : SANITIZE_OPTS_ALLOW_TAGS
          )
            .toString()
            .trim();
          setValue(`attributeList.${fieldName}`, sanitizedValue);
        }
      };
      return (
        <Grid item mobile={colspan}>
          <StyledTextField
            fullWidth
            disabled={disabled}
            label={required ? fieldLabel + "*" : fieldLabel}
            InputLabelProps={{
              shrink: true,
              htmlFor: fieldId,
            }}
            id={fieldId}
            {...register(`attributeList.${fieldName}`, {
              required,
            })}
            onBlur={handleBlur}
            error={!!error}
            helperText={error && `${fieldLabel} is required`}
            data-testid={`cy-${fieldId}`}
          />
        </Grid>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors]
  );
  const renderValidationField = useCallback(
    (
      fieldName: keyof ItemAttributeList,
      fieldId: string,
      disabled: boolean,
      required: boolean,
      validationRules: Record<string, unknown>,
      purify: boolean,
      fullWidth?: boolean,
      allowTags: boolean = false
    ) => {
      const colspan = !!fullWidth ? 12 : 6;
      const { attributeList: attributeErrors } = errors;
      const error =
        typeof attributeErrors !== "undefined"
          ? attributeErrors[fieldName]
          : undefined;
      const attr = getAttributeByKeyName(fieldName);
      const fieldLabel = attr?.name ?? "";
      const handleBlur = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        if (!purify) {
          setValue(`attributeList.${fieldName}`, event.target.value);
        } else {
          const sanitizedValue = DOMPurify.sanitize(
            event.target.value,
            !allowTags ? SANITIZE_OPTS_NO_TAGS : SANITIZE_OPTS_ALLOW_TAGS
          )
            .toString()
            .trim();
          setValue(`attributeList.${fieldName}`, sanitizedValue);
        }
      };
      return (
        <Grid item mobile={colspan}>
          <StyledTextField
            fullWidth
            disabled={disabled}
            label={required ? fieldLabel + "*" : fieldLabel}
            InputLabelProps={{
              shrink: true,
              htmlFor: fieldId,
            }}
            id={fieldId}
            {...register(`attributeList.${fieldName}`, {
              required,
              ...validationRules,
            })}
            onBlur={handleBlur}
            error={!!error}
            helperText={error && error.message}
            data-testid={`cy-${fieldId}`}
          />
        </Grid>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors]
  );

  const renderMinLengthField = useCallback(
    (
      fieldName: keyof ItemAttributeList,
      fieldId: string,
      disabled: boolean,
      required: boolean,
      minLength: number,
      purify: boolean,
      allowTags: boolean = false
    ) => {
      const { attributeList: attributeErrors } = errors;
      const error =
        typeof attributeErrors !== "undefined"
          ? attributeErrors[fieldName]
          : undefined;
      const attr = getAttributeByKeyName(fieldName);
      const fieldLabel = attr?.name ?? "";
      const handleBlur = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        if (!purify) {
          setValue(`attributeList.${fieldName}`, event.target.value);
        } else {
          const sanitizedValue = DOMPurify.sanitize(
            event.target.value,
            !allowTags ? SANITIZE_OPTS_NO_TAGS : SANITIZE_OPTS_ALLOW_TAGS
          )
            .toString()
            .trim();
          setValue(`attributeList.${fieldName}`, sanitizedValue);
        }
      };
      return (
        <Grid item mobile={6}>
          <StyledTextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={required ? fieldLabel + "*" : fieldLabel}
          //  disabled={!!isReaderRole || !editable}
            disabled={disabled}
            {...register(`attributeList.${fieldName}`, {
              validate: (value) => {
                return (
                  value.trim().length >= minLength ||
                  `This field must be at least ${minLength} alphanumeric characters long.`
                );
              },
            })}
            onBlur={handleBlur}
            error={!!error}
            helperText={error && error.message}
            data-testid={`cy-${fieldId}`}
          />
        </Grid>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors, isReaderRole, editable]
  );

  const renderFocusableField = useCallback(
    (
      fieldName: keyof ItemFocusableInputFields,
      fieldId: string,
      disabled: boolean,
      purify: boolean,
      allowTags: boolean = false
    ) => {
      const { attributeList: attributeErrors } = errors;
      const error =
        typeof attributeErrors !== "undefined"
          ? attributeErrors[fieldName]
          : undefined;
      const attr = getAttributeByKeyName(fieldName);
      const fieldLabel = attr?.name ?? "";
      const handleBlur = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        handleFieldFocus(fieldName, false);
        if (!purify) {
          setValue(`attributeList.${fieldName}`, event.target.value);
        } else {
          const sanitizedValue = DOMPurify.sanitize(
            event.target.value,
            !allowTags ? SANITIZE_OPTS_NO_TAGS : SANITIZE_OPTS_ALLOW_TAGS
          )
            .toString()
            .trim();
          setValue(`attributeList.${fieldName}`, sanitizedValue);
        }
      };
      return (
        <Grid item mobile={12}>
          <StyledTextField
            label={fieldLabel}
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
              htmlFor: fieldId,
            }}
            id={fieldId}
            multiline
            fullWidth
            rows={focusedFields[fieldName] ? 8 : 2}
            {...register(`attributeList.${fieldName}`)}
            error={!!error}
            data-testid={`cy-${fieldId}`}
            onFocus={() => handleFieldFocus(fieldName, true)}
            onBlur={handleBlur}
          />
        </Grid>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors, focusedFields]
  );
  const isSitemapEnabled = watch("attributeList.sitemap") === "Yes";
  useEffect(() => {
    if (!isSitemapEnabled) {
      setValue("attributeList.frequency", "");
      setValue("attributeList.priority", "");
    }
  }, [isSitemapEnabled, setValue]);

  const handleFooterChange = (data: any) => {
    const selectedFooters = data.map((foot: FooterType) => ({
      value: foot.footerText,
      id: foot.footerId,
    }));
    setValue("footerList", selectedFooters, { shouldDirty: true });
  };
  return (
    <StyledRelativeContainer container spacing={1}>
      {isLoading && <Loader />}
      <Grid container spacing={1} sx={{ m: 0 }}>
        <Grid mobile={12} sx={{ my: 3 }}>
          <Grid container>
            <Grid
              container
              mobile={12}
              sx={{ mb: 6, display: "flex", justifyContent: "space-between" }}
            >
              <Typography variant="h2">Marketing Information</Typography>
              <StyledSecondaryButton
                variant="contained"
                disabled={!isLive}
                onClick={() => {
                  navigate(`/items/${itemId}/compare-with-live`);
                }}
              >
                Compare with live
              </StyledSecondaryButton>
            </Grid>
            <Grid
              container
              mobile={12}
              sx={{ mb: 2, justifyContent: "flex-end" }}
            >
              <LanguageSelector
                selectedLanguage={language!}
                disabled={typeof itemId === "undefined"}
                onChange={(language) => setLanguage(language)}
              />
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item mobile={12}>
                <StyledTextField
                  fullWidth
                  label="Item Type*"
                  select
                  disabled={!!isReaderRole || !editable || isEditableProductType}
                  {...register("attributeList.item_type", { required: true })}
                  SelectProps={{ native: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  data-testid="item-type-selector"
                >
                  {getDropDownValuesByKeyName("item_type").map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.value}
                    </option>
                  ))}
                </StyledTextField>
              </Grid>
              {selectedItemType === "Item Collection" && (
                <>
                  <Grid item mobile={12}>
                    <StyledTextField
                      fullWidth
                      label="Time of Day"
                      select
                      //this was not letting change the timeOfDay value so commented this Plaform 9440 amanjare
                     // value={getAttributeByKeyName("time_of_day")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...register("attributeList.time_of_day")}
                      required
                      disabled={!!isReaderRole || !editable || isEditableTimeOfDay}
                      SelectProps={{ native: true }}
                    >
                      {getDropDownValuesByKeyName("time_of_day").map(
                        (option) => (
                          <option key={option.id} value={option.value}>
                            {option.value}
                          </option>
                        )
                      )}
                    </StyledTextField>
                  </Grid>
                  <Grid
                    item
                    container
                    mobile={12}
                    sx={{ width: "100%", display: "flex" }}
                  >
                    <ItemCollection
                      chosenCollectionItems={itemCollectionItems}
                      onItemCollectionChange={handleItemCollectionDataChange}
                    />
                  </Grid>
                </>
              )}
              {selectedItemType && (
                <>
                  {renderMinLengthField(
                    "item_name",
                    "item-name-input",
                    !!isReaderRole || !editable || isEditableItemName,
                    true,
                    3,
                    true
                  )}
                  <Grid item mobile={6}>
                    <StyledTextField
                      label="Name Suffix"
                      fullWidth
                      select
                      value={legalSuffix}
                      onChange={(e) => setLegalSuffix(e.currentTarget.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={!isLegalRole}
                      SelectProps={{ native: true }}
                    >
                      <option>Select</option>
                      <option value="®">®</option>
                      <option value=".">.</option>
                      <option value=".®">.®</option>
                      <option value="®.">®.</option>
                    </StyledTextField>
                  </Grid>
                  {renderMinLengthField(
                    "item_marketing_name",
                    "item-marketing-name-input",
                    !!isReaderRole || !editable || isEditableMarketingName,
                    true,
                    3,
                    true
                  )}

                  {renderValidationField(
                    "short_name",
                    "shortName",
                    !!isReaderRole || !editable || isEditableShortName,
                    false,
                    {
                      pattern: {
                        value: /^[a-z0-9][a-z0-9-]*[a-z0-9]$/,
                        message:
                          "Incorrect Short name. Only alphanumeric with hyphen allowed in lower case. Should start and end with alphanumeric only.",
                      },
                    },
                    true
                  )}
                  {allAttributeKeyNames.includes("genesis_item_name") &&
                    renderSimpleField(
                      "genesis_item_name",
                      "imported-item-name-input",
                      !!isReaderRole || !editable  || isEditableGenesisItemName,
                      false,
                      true,
                      true
                    )}
                  {allAttributeKeyNames.includes("imported_item_name") &&
                    renderSimpleField(
                      "imported_item_name",
                      "imported-item-name-input",
                      !!isReaderRole || !editable || isEditableImportedItemName,
                      false,
                      true,
                      true
                    )}
                  {itemIdAttribute && (
                    <Grid item mobile={6}>
                      <StyledTextField
                        fullWidth
                        disabled={isEditableItemId}
                        label={itemIdAttribute.name}
                        value={itemIdAttribute.value ?? ""}
                        InputLabelProps={{
                          shrink: true,
                          htmlFor: "item-id-input",
                        }}
                        id="item-id-input"
                        data-testid={`cy-item-id-input`}
                      />
                    </Grid>
                  )}
                  {renderValidationField(
                    "menu_item_no",
                    "menu-item-no-input",
                    !!isReaderRole || !editable || isEditableMenuItemNumber,
                    true,
                    {
                      pattern: {
                        value: /^[a-zA-Z0-9_-]*$/,
                        message:
                          "Incorrect Menu Item No. Only alphanumeric with hyphen allowed.",
                      },
                    },
                    !itemId ? true : false
                  )}
                  {allAttributeKeyNames.includes("import_id") &&
                    renderSimpleField(
                      "import_id",
                      "import-id-input",
                      !!isReaderRole || !editable || isEditableImportId,
                      false,
                      true,
                      true
                    )}
                  {allAttributeKeyNames.includes("genesis_menu_item_no") &&
                    renderValidationField(
                      "genesis_menu_item_no",
                      "genesis-menu-item-no-input",
                      !!isReaderRole || !editable|| isEditableGenesisMenuItemNumber,
                      false,
                      {
                        pattern: {
                          value: /^[a-zA-Z0-9_-]*$/,
                          message:
                            "Incorrect Menu Item No. Only alphanumeric with hyphen allowed.",
                        },
                      },
                      !itemId ? true : false,
                      true
                    )}
                  {
                    <>
                      <Grid item mobile={12}>
                        <StyledTextField
                          fullWidth
                          label="Do Not Show"
                          select
                          disabled={!!isReaderRole || !editable || isEditableDoNotShow}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register("attributeList.do_not_show")}
                          SelectProps={{ native: true }}
                        >
                          {getDropDownValuesByKeyName("do_not_show").map(
                            (option) => (
                              <option key={option.id} value={option.value}>
                                {option.value}
                              </option>
                            )
                          )}
                        </StyledTextField>
                      </Grid>
                    </>
                  }
                  {allAttributeKeyNames.includes("pim_id") &&
                    renderSimpleField(
                      "pim_id",
                      "pim-id-input",
                      isEditablePimId,
                      false,
                      false,
                      true,
                      true
                    )}
                  {allAttributeKeyNames.includes("pim_item_name") &&
                    renderSimpleField(
                      "pim_item_name",
                      "pim-item-name-input",
                      isEditablePimItemName,
                      false,
                      false,
                      true,
                      true
                    )}
                  {allAttributeKeyNames.includes("serves") &&
                    renderValidationField(
                      "serves",
                      "item-serves-input",
                      !!isReaderRole || !editable || isEditableServes,
                      false,
                      {
                        pattern: {
                          value: /^[0-6]$/,
                          message:
                            "Incorrect value for Serves. Serves cannot exceed 6.",
                        },
                      },
                      true,
                      true
                    )}
                  {renderFocusableField(
                    "description",
                    "item-description-input",
                    !!isReaderRole || !editable || isEditableDescription,
                    true,
                    true
                  )}
                  {renderFocusableField(
                    "item_comments",
                    "item-comments-input",
                    !!isReaderRole || !editable || isEditableComments,
                    true,
                    true
                  )}
                  {renderFocusableField(
                    "keywords",
                    "item-keywords-input",
                    !!isReaderRole || !editable || isEditableKeywords,
                    true
                  )}
                  {renderFocusableField(
                    "item_ingredient_statement",
                    "item-ingredient-statement-input",
                    !!isReaderRole || !editable || isEditableIngredientStatement,
                    true,
                    true
                  )}
                  {renderFocusableField(
                    "item_additional_text_ingredient_statement",
                    "item-additional-ingredient-statement-input",
                    !!isReaderRole || !editable || isEditableAdditionalTextIngredientStatement,
                    true,
                    true
                  )}
                  {renderFocusableField(
                    "item_allergen",
                    "item-allergen-input",
                    !!isReaderRole || !editable || isEditableAllergen,
                    true,
                    true
                  )}
                  {renderFocusableField(
                    "item_additional_allergen",
                    "item-additional-allergen-input",
                    !!isReaderRole || !editable || isEditableAdditionalAllergen,
                    true,
                    true
                  )}
                  {renderFocusableField(
                    "text",
                    "item-nutrition-quality-statement-input",
                    !!isReaderRole || !editable || isEditableText,
                    true
                  )}
                  {renderSimpleField(
                    "item_meta_title",
                    "item-meta-title-input",
                    !!isReaderRole || !editable || isEditableMetaTitle,
                    false,
                    true,
                    true
                  )}
                  {renderFocusableField(
                    "item_meta_description",
                    "item-meta-description-input",
                    !!isReaderRole || !editable || isEditableMetaDescription,
                    true,
                    true
                  )}
                  {renderSimpleField(
                    "customization_label",
                    "customization_label-input",
                    !!isReaderRole || !editable || isEditableCustomizationLabel,
                    false,
                    true,
                    false
                  )}
                  {renderSimpleField(
                    "customization_button",
                    "customization_button-input",
                    !!isReaderRole || !editable || isEditableCustomizationButton,
                    false,
                    true,
                    false
                  )}
                  <Grid
                    container
                    item
                    mobile={12}
                    sx={{ alignItems: "center" }}
                  >
                    <Typography>Show Product Images?</Typography>
                    <Controller
                      name="attributeList.show_product_images"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          disabled={!!isReaderRole || !editable || isEditableShowProductImages}
                          checked={field.value === "Yes"}
                          onChange={(e) =>
                            field.onChange(e.target.checked ? "Yes" : "No")
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item mobile={4}>
                    <StyledTextField
                      fullWidth
                      label="Logo 1"
                      select
                      disabled={!!isReaderRole || !editable || isEditableLogo1}
                      {...register("attributeList.logo_1")}
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {getDropDownValuesByKeyName("logoList").map((option) => (
                        <option key={option.id} value={option.value}>
                          {option.value}
                        </option>
                      ))}
                    </StyledTextField>
                  </Grid>
                  <Grid item mobile={4}>
                    <StyledTextField
                      fullWidth
                      label="Logo 2"
                      select
                      disabled={!!isReaderRole || !editable || isEditableLogo2}
                      {...register("attributeList.logo_2")}
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {getDropDownValuesByKeyName("logoList").map((option) => (
                        <option key={option.id} value={option.value}>
                          {option.value}
                        </option>
                      ))}
                    </StyledTextField>
                  </Grid>
                  <Grid item mobile={4}>
                    <StyledTextField
                      fullWidth
                      label="Logo 3"
                      select
                      disabled={!!isReaderRole || !editable || isEditableLogo3}
                      {...register("attributeList.logo_3")}
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {getDropDownValuesByKeyName("logoList").map((option) => (
                        <option key={option.id} value={option.value}>
                          {option.value}
                        </option>
                      ))}
                    </StyledTextField>
                  </Grid>
                  <Grid item mobile={12}>
                    <StyledTextField
                      fullWidth
                      label="Status Flag"
                      select
                      disabled={!!isReaderRole || !editable || isEditableItemStatusFlag}
                      {...register("attributeList.item_status_flag")}
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {getDropDownValuesByKeyName("item_status_flag").map(
                        (option) => (
                          <option key={option.id} value={option.value}>
                            {option.value}
                          </option>
                        )
                      )}
                    </StyledTextField>
                  </Grid>
                  {renderSimpleField(
                    "specialization_text1",
                    "specialization-text-1-input",
                    !!isReaderRole || !editable || isEditableSpecializationText1,
                    false,
                    true,
                    true
                  )}
                  {renderSimpleField(
                    "specialization_text2",
                    "specialization-text-2-input",
                    !!isReaderRole || !editable || isEditableSpecializationText2,
                    false,
                    true,
                    true
                  )}
                  <Grid
                    container
                    item
                    mobile={12}
                    sx={{ alignItems: "center" }}
                  >
                    <Typography>SiteMap</Typography>
                    <Controller
                      name="attributeList.sitemap"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          disabled={!!isReaderRole || !editable || isEditableSiteMap}
                          checked={field.value === "Yes"}
                          onChange={(e) => {
                            field.onChange(e.target.checked ? "Yes" : "No");
                            setValue("attributeList.frequency", "Weekly");
                            setValue("attributeList.priority", "0.8");
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item mobile={6}>
                    <Controller
                      control={control}
                      name="attributeList.frequency"
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          fullWidth
                          label="Frequency"
                          select
                          disabled={
                            !!isReaderRole || !editable || !isSitemapEnabled
                          }
                          SelectProps={{ native: true }}
                          InputLabelProps={{ shrink: true }}
                        >
                          <option key={0} value=""></option>
                          {getDropDownValuesByKeyName("frequency").map(
                            (option) => (
                              <option key={option.id} value={option.value}>
                                {option.value}
                              </option>
                            )
                          )}
                        </StyledTextField>
                      )}
                    />
                  </Grid>
                  <Grid item mobile={6}>
                    <Controller
                      control={control}
                      name="attributeList.priority"
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          fullWidth
                          label="Priority"
                          select
                          disabled={
                            !!isReaderRole || !editable || !isSitemapEnabled
                          }
                          SelectProps={{ native: true }}
                          InputLabelProps={{ shrink: true }}
                        >
                          <option key={0} value={""}></option>
                          {getDropDownValuesByKeyName("priority").map(
                            (option) => (
                              <option key={option.id} value={option.value}>
                                {option.value}
                              </option>
                            )
                          )}
                        </StyledTextField>
                      )}
                    />
                    
                   
                  </Grid>
                  <Grid
                        container
                        item
                        mobile={12}
                        sx={{ alignItems: "center" }}
                      >
                        <>
                          <Typography>Is Fortified Item</Typography>
                          <Controller
                            name="attributeList.is_fortified_menu_item"
                            control={control}
                            render={({ field }) => (
                              <Checkbox
                                disabled={!!isReaderRole || !editable || !!isLegalRole || !editable || isEditableIsFortifiedMenuItem}
                             
                                checked={field.value === "Yes"}
                                onChange={(e) =>
                                  field.onChange(e.target.checked ? "Yes" : "No")
                                }
                              />
                            )}
                          />
                        </>
                      </Grid>
                  {renderValidationField(
                    "attach_item_thumbnail_image",
                    "attach_item_thumbnail_image-input",
                    !!isReaderRole || !editable || isEditableAttachItemThumbnailImage,
                    false,
                    {
                      pattern: {
                        value: /^[a-zA-Z0-9_\-/ ]+(\.(jpe?g|png|gif))?$/i,
                        message: "Invalid image file",
                      },
                    },
                    true,
                    true
                  )}
                  {renderValidationField(
                    "attach_item_hero_image",
                    "attach_item_hero_image-input",
                    !!isReaderRole || !editable || isEditableAttachItemHeroImage,
                    false,
                    {
                      pattern: {
                        value: /^[a-zA-Z0-9_\-/ ]+(\.(jpe?g|png|gif))?$/i,
                        message: "Invalid image file",
                      },
                    },
                    true,
                    true
                  )}
                  {renderValidationField(
                    "attach_transparent_icon_image",
                    "attach_transparent_icon_image-input",
                    !!isReaderRole || !editable || isEditableAttachTransparentIconImage,
                    false,
                    {
                      pattern: {
                        value: /^[a-zA-Z0-9_\-/ ]+(\.(jpe?g|png|gif))?$/i,
                        message: "Invalid image file",
                      },
                    },
                    true,
                    true
                  )}
                  {renderValidationField(
                    "attach_meal_bundle_image",
                    "attach_meal_bundle_image-input",
                    !!isReaderRole || !editable || isEditableAttachMealBundleImage,
                    false,
                    {
                      pattern: {
                        value: /^[a-zA-Z0-9_\-/ ]+(\.(jpe?g|png|gif))?$/i,
                        message: "Invalid image file",
                      },
                    },
                    true,
                    true
                  )}
                  { notSupportedMessage && notSupportedMessage.includes("not supported") ? (
                null ) : ( 
                  <Grid item mobile={12}>
                    <StyledFooterList>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignContent="center"
                        sx={{ py: 2, px: 2 }}
                      >
                        <Grid>
                          <StyledFooterListTitle>
                            <Typography variant="h6">{`Footer`}</Typography>
                          </StyledFooterListTitle>
                        </Grid>
                        <Grid>
                          <StyledSecondaryButton
                            size="small"
                            variant="contained"
                            onClick={() => {
                              setIsFooterModalOpen(true);
                            }}
                          >
                            Select Footers From Master List
                          </StyledSecondaryButton>
                        </Grid>
                      </Grid>
                      <List>
                        {editableFooters.map((footer) => {
                          return (
                            <ListItem disablePadding>
                              <Grid container mobile={12} alignItems="center">
                                <ListItemButton>
                                  <Grid
                                    container
                                    item
                                    mobile={11}
                                    alignItems="center"
                                  >
                                    <Grid mobile={1}>
                                      <StyledListItemIcon>
                                        &bull;
                                      </StyledListItemIcon>
                                    </Grid>
                                    <Grid mobile={11}>
                                      <Typography variant="body2">
                                        {footer.value}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid container item mobile={1}>
                                    <Tooltip title="Remove Footer">
                                      <IconButton
                                        aria-label="delete button"
                                        onClick={() =>
                                          handleRemoveFooter(footer.id)
                                        }
                                        disabled={!!isReaderRole || !editable}
                                        role="button"
                                        data-testid="delete-item-btn"
                                        sx={{ color: "#DA291C" }}
                                      >
                                        <DeleteForeverIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </ListItemButton>
                              </Grid>
                            </ListItem>
                          );
                        })}
                      </List>
                    </StyledFooterList>
                  </Grid>
                )}
                </>
              )}
            </Grid>
            {!isReaderRole && editable && (
              <Grid
                mobile={12}
                sx={{
                  my: 4,
                  p: 0,
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <StyledButton
                  type="submit"
                  variant="contained"
                  size="large"
                  aria-label="Save"
                  data-testid="save-item-submit-button"
                >
                  Save
                </StyledButton>
                {!itemId ? (
                  <StyledSecondaryButton
                    variant="contained"
                    size="large"
                    aria-label="Reset"
                    disabled={!isDirty}
                    onClick={() => reset()}
                  >
                    Reset
                  </StyledSecondaryButton>
                ) : (
                  <StyledDangerButton
                    variant="contained"
                    size="large"
                    aria-label="cancel"
                    disabled={!isDirty}
                    onClick={handleCancel}
                  >
                    Cancel
                  </StyledDangerButton>
                )}
              </Grid>
            )}
          </form>
        </Grid>
      </Grid>
      {isFooterModalOpen && (
        <ManageFootersModal
          onAddFooter={handleFooterChange}
          onCancel={() => setIsFooterModalOpen(false)}
          language={language}
          mappedFooters={editableFooters.map((foot) => ({
            footerText: foot.value,
            footerId: foot.id,
          }))}
        />
      )}
      <UnsavedChangesModal
        open={showUnsavedChangesModal}
        onClose={() => setShowUnsavedChangesModal(false)}
        onLeave={handleLeaveNavigation}
      />
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          const formValues = getValues();
          onSubmit(formValues);
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={handleSaveSuccess}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
export const StyledSecondaryButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#264689",
  "&:hover": {
    backgroundColor: "#264689",
  },
  "&:focus": {
    backgroundColor: "#264689",
  },
  "&:active": {
    backgroundColor: "#264689",
  },
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
export const StyledDangerButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#DA291C",
  "&:hover": {
    backgroundColor: "#DA291C",
  },
  "&:focus": {
    backgroundColor: "#DA291C",
  },
  "&:active": {
    backgroundColor: "#DA291C",
  },
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledFooterList = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledFooterListTitle = styled(ListItemText)(({ theme }) => ({
  textAlign: "center",
}));
const StyledListItemIcon = styled(ListItemIcon)({
  justifyContent: "center",
});
