import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Loader } from "./loader/Loader";
import { useItem } from "../pages/item";
import { styled } from "@mui/material/styles";
import {
  getIngredientsByItemAndProductIds,
  getItemServingSize,
  getNutritionFactsByProductIds,
  getProductsByItemId,
  getSelectedElementsMapping,
  getUomDetails,
  recalculateServingSize,
  saveItemServingSize,
  saveNutritionInformationByItemId,
} from "../data/items";
import { useAxios } from "../axios-provider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { RoleContext } from "../role-provider";
import {
  ProductIngredient,
  ServingSizeNutritionData,
  UomData,
  ValidNutritionFact,
  ValidSelectedElementMapping,
  formatAndFilterNutritionFacts,
  formatProductIngredient,
  isElementMapping,
  isUom,
  formatSelectableProduct,
  formatSelectableItem,
  SelectableMutexGroup,
  SelectableItemOrProduct,
  isDefined,
  formatMutexOption,
  MutexOption,
  ApiError,
  formatServingSize,
  // formattedNumStringOrNA,
  // localizedNumStringOrNA,
  // localizedNumStringOrEmpty,
} from "../utils";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import { LanguageSelector } from "./LanguageSelector";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";
import FortifiedItem from "./FortifiedItem";
import {
  standardizedNumStringOrNA,
  localizedNumStringOrEmpty,
  localizedNumStringOrNA,
} from "../util/number-localization";

function formattedNutrientOrNA(value: string, unit: string): string {
  if (value === "" || value.toLowerCase() === "n/a") {
    return "N/A";
  }
  return `${value}${unit}`;
}

type ServingSizeFormValues = {
  servingSizes: ServingSizeNutritionData[];
};
type AllNutritionFactsFormValues = {
  servingSizes: ServingSizeNutritionData[];
  nutrients: ValidNutritionFact[];
};

function servingSizeToNutritionFact(
  servingSize: ServingSizeNutritionData,
  marketCode: string
  // isMarketLocale: Boolean

): ValidNutritionFact {
  return {
    nutrientFactId: servingSize.nutrientId,
    nutrientFactName: servingSize.nutrientName,
    nutrientValue: standardizedNumStringOrNA(
      servingSize.nutrientValue,
      marketCode
    //  isMarketLocale
    ),
    uomName: servingSize.uomName,
    dvValue: "N/A",
    hundredGramPerProduct: "N/A",
  };
}

type ServingSizesOnlyProps = {
  servingSizes: ServingSizeNutritionData[];
  uomOptions: UomData[];
  selectedLanguage: string | null;
  recalculateEnableLatest: Boolean | false;
  isFetching: Boolean;
};

const ServingSizesOnlyForm = ({
  servingSizes,
  uomOptions,
  selectedLanguage,
  recalculateEnableLatest,
  isFetching,
}: ServingSizesOnlyProps) => {
  const { itemId, editable, hasComponents } = useItem();
  const { selectedCountry, selectedRole, isReaderRole } =
    useContext(RoleContext);
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();

  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ServingSizeFormValues>({
    defaultValues: {
      servingSizes,
    },
  });
  const editableServingSizes = watch("servingSizes");

  useEffect(() => {
    setValue("servingSizes", servingSizes);
  }, [servingSizes, setValue]);

  const saveItemServingSizeRequest = saveItemServingSize(apiClient);
  const saveItemServingSizeMutation = useMutation(
    (servingSizes: ServingSizeNutritionData[]) => {
      const formattedServingSizes = servingSizes.map((servingSize) => {
        return {
          ...servingSize,
          nutrientValue: standardizedNumStringOrNA(
            servingSize.nutrientValue,
            selectedCountry!
          ),
        };
      });
      return saveItemServingSizeRequest(formattedServingSizes, {
        itemId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
        roleId: String(selectedRole)!,
      });
    },
    {
      onMutate: () => saveItemServingSizeRequest,
      onSuccess: () =>
        setSuccessMessage("Serving Size information saved successfully"),
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const resetToDefaultsRequest = recalculateServingSize(apiClient);
  const resetToDefaultsMutation = useMutation(
    () => {
      return resetToDefaultsRequest({
        itemId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
      });
    },
    {
      onMutate: () => resetToDefaultsRequest,
      onSuccess: () => {
        setSuccessMessage("Serving Size information reset to original values");
      },
      onError: (error: AxiosError) => {
        if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const isWorking = useMemo(
    () =>
      isFetching ||
      saveItemServingSizeMutation.isLoading ||
      resetToDefaultsMutation.isLoading,
    [
      isFetching,
      saveItemServingSizeMutation.isLoading,
      resetToDefaultsMutation.isLoading,
    ]
  );

  const saveServingSizes = (data: ServingSizeFormValues) => {
    // Only proceed if there are no errors
    if (Object.keys(errors).length === 0) {
      saveItemServingSizeMutation.mutate(data.servingSizes);
    } else {
    }
  };
  const resetToDefaults = () => resetToDefaultsMutation.mutate();

  const handleUomChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // set uomName based on selected uomId
    const selectedOption = uomOptions.find(
      (option) => option.uomId.toString() === event.target.value
    );
    if (selectedOption) {
      setValue(`servingSizes.${index}.uomName`, selectedOption.uomName);
    }
  };

  const handleSuccessAlertClose = useCallback(() => {
    setSuccessMessage(null);
    queryClient.invalidateQueries(["getItemServingSize", { itemId }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleErrorAlertClose = useCallback(() => setErrorMessage(null), []);

  const renderServingSizeFields = () => {
    return editableServingSizes.map((servingSize, index) => (
      <StyledServingSizeField
        container
        mobile={12}
        key={index}
        sx={{ mb: 2, alignItems: "flex-start" }}
      >
        <Grid mobile={3}>
          <Typography variant="normalBold">
            {servingSize.nutrientName}:
          </Typography>
        </Grid>
        <Grid>
          <Controller
            name={`servingSizes.${index}.nutrientValue`}
            control={control}
            defaultValue={servingSize.nutrientValue}
            render={({ field }) => (
              <TextField
                disabled={!!isReaderRole || !editable}
                size="small"
                {...register(`servingSizes.${index}.nutrientValue`, {
                  required: "Value is required.",
                })}
                error={Boolean(errors.servingSizes?.[index]?.nutrientValue)}
                helperText={
                  errors.servingSizes?.[index]?.nutrientValue?.message
                }
                onFocus={() => {
                  setValue(
                    `servingSizes.${index}.nutrientValue`,
                    standardizedNumStringOrNA(
                      getValues(`servingSizes.${index}.nutrientValue`),
                      selectedCountry!

                    )
                  );
                }}
                onBlur={(e) => {
                  setValue(
                    `servingSizes.${index}.nutrientValue`,
                    localizedNumStringOrEmpty(e.target.value, selectedCountry!)
                  );
                  field.onBlur();
                }}
                sx={{ width: 124 }}
              />
            )}
          />
        </Grid>
        <Grid>
          <TextField
            disabled={!!isReaderRole || !editable}
            fullWidth
            size="small"
            label="Units"
            {...register(`servingSizes.${index}.uomId`, {
              required: "Unit is required.",
            })}
            select
            SelectProps={{ native: true }}
            onChange={(e) => handleUomChange(index, e)}
          >
            {uomOptions.map((option) => (
              <option key={option.uomName} value={option.uomId}>
                {option.uomName}
              </option>
            ))}
          </TextField>
        </Grid>
      </StyledServingSizeField>
    ));
  };
  return (
    <StyledRelativeContainer>
      <>
        {isWorking && (
          <StyledRelativeContainerShort>
            <Loader />
          </StyledRelativeContainerShort>
        )}
        {!isWorking && editableServingSizes.length > 0 && (
          <StyledFormContainer>
            <form
              onSubmit={handleSubmit(saveServingSizes)}
              data-testid="edit-serving-size-form"
            >
              <Grid container mobile={12} spacing={0}>
                {renderServingSizeFields()}
                {!hasComponents ? (
                  <StyledFlexEndContainer container mobile={12}>
                    <StyledButton
                      variant="contained"
                      size="large"
                      type="submit"
                      disabled={isWorking || !!isReaderRole}
                    >
                      Save
                    </StyledButton>
                  </StyledFlexEndContainer>
                ) : (
                  <StyledSpaceBetweenContainer container mobile={12}>
                    <StyledButton
                      variant="contained"
                      onClick={resetToDefaults}
                      disabled={!!isReaderRole || recalculateEnableLatest}
                    >
                      Recalculate
                    </StyledButton>
                    <StyledButton
                      variant="contained"
                      size="large"
                      type="submit"
                      disabled={isWorking || !!isReaderRole}
                    >
                      Save
                    </StyledButton>
                  </StyledSpaceBetweenContainer>
                )}
              </Grid>
            </form>
          </StyledFormContainer>
        )}

        {!isWorking && editableServingSizes.length === 0 && (
          <StyledEmptyBox>
            <Typography variant="h6">{`No serving size information to display`}</Typography>
          </StyledEmptyBox>
        )}
      </>
      <FortifiedItem
        {...{
          selectedLanguage,
        }}
      />
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          const formValues = getValues();
          saveServingSizes(formValues);
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={handleSuccessAlertClose}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={handleErrorAlertClose}
      />
    </StyledRelativeContainer>
  );
};

type AllNutritionFactsProps = {
  servingSizes: ServingSizeNutritionData[];
  uomOptions: UomData[];
  onError: (error: string) => void;
  onSuccess: (message: string) => void;
  selectedLanguage: string | null;
};

const AllNutritionFacts = ({
  servingSizes,
  uomOptions,
  onError,
  onSuccess,
  selectedLanguage,
}: AllNutritionFactsProps) => {
  const { selectedCountry, isReaderRole, selectedRole } =
    useContext(RoleContext);
  const { itemId, editable } = useItem();
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();

  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);

  const nutritionFactsQuery = useCustomQuery(
    ["getNutritionFactsByProductIds", { itemId, selectedLanguage }],
    () =>
      getNutritionFactsByProductIds(apiClient)({
        itemId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
      })
  );

  const nutritionFacts: ValidNutritionFact[] = useMemo(() => {
    if (nutritionFactsQuery.data?.data.nutrientFacts === undefined) {
      return [];
    }
    const validNutritionFacts: ValidNutritionFact[] =
      formatAndFilterNutritionFacts(
        nutritionFactsQuery.data?.data.nutrientFacts,
        true
      );
    return validNutritionFacts.map((nutritionFact) => ({
      ...nutritionFact,
      nutrientValue: localizedNumStringOrNA(
        nutritionFact.nutrientValue,
        selectedCountry!
      ),
      dvValue: localizedNumStringOrNA(nutritionFact.dvValue, selectedCountry!),
      hundredGramPerProduct: localizedNumStringOrNA(
        nutritionFact.hundredGramPerProduct,
        selectedCountry!
      ),
    }));
  }, [nutritionFactsQuery.data, selectedCountry]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    register,
    reset,
    formState: { errors, isDirty },
  } = useForm<AllNutritionFactsFormValues>({
    defaultValues: {
      servingSizes,
      nutrients: nutritionFacts,
    },
  });
  useEffect(() => {
    // Whenever attributes change, update the form's default values
    const defaultValues = {
      servingSizes,
      nutrients: nutritionFacts,
    };
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nutritionFacts, servingSizes]);
  const saveNutritionInformationByItemIdRequest =
    saveNutritionInformationByItemId(apiClient);
  const saveNutritionInfoMutation = useMutation(
    (data: AllNutritionFactsFormValues) => {
      const { servingSizes, nutrients } = data;
      const formattedServingSizes: ValidNutritionFact[] = servingSizes.map(
        (servingSize) => {
          return servingSizeToNutritionFact(servingSize, selectedCountry!);
        }
      );
      const formattedNutritionFacts = nutrients.map((nutritionFact) => {
        return {
          ...nutritionFact,
          nutrientValue: standardizedNumStringOrNA(
            nutritionFact.nutrientValue,
            selectedCountry!
          ),
          dvValue: standardizedNumStringOrNA(
            nutritionFact.dvValue,
            selectedCountry!
          ),
          hundredGramPerProduct: standardizedNumStringOrNA(
            nutritionFact.hundredGramPerProduct,
            selectedCountry!
          ),
        };
      });
      // package serving sizes in with nutrition facts
      const combinedNutritionFacts = [
        ...formattedServingSizes,
        ...formattedNutritionFacts,
      ];

      return saveNutritionInformationByItemIdRequest(combinedNutritionFacts, {
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
        itemId: Number(itemId),
        roleId: Number(selectedRole)!,
      });
    },
    {
      onMutate: () => saveNutritionInformationByItemIdRequest,
      onSuccess: () => {
        queryClient.invalidateQueries(["item/getCompareCurrent", { itemId }]);
        queryClient.invalidateQueries([
          "getNutritionFactsByProductIds",
          { itemId },
        ]);
        queryClient.invalidateQueries(["getItemServingSize", { itemId }]);
        onSuccess(`Nutrition Information for ${itemId} was saved successfully`);
        setValue("nutrients", nutritionFacts, { shouldDirty: true });
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          onError(String(errorMessage.message));
        } else {
          onError(String(error));
        }
      },
    }
  );

  useEffect(() => {
    setValue("nutrients", nutritionFacts, { shouldDirty: false });
  }, [nutritionFacts, setValue]);

  const editableServingSizes = watch("servingSizes");

  const onSubmit = (data: AllNutritionFactsFormValues) => {
    saveNutritionInfoMutation.mutate(data);
  };

  const handleUomChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // set uomName based on selected uomId
    const selectedOption = uomOptions.find(
      (option) => option.uomId.toString() === event.target.value
    );
    if (selectedOption) {
      setValue(`servingSizes.${index}.uomName`, selectedOption.uomName);
    }
  };

  return (
    <>
      {(nutritionFactsQuery.isFetching ||
        saveNutritionInfoMutation.isLoading) && <Loader />}
      {nutritionFacts && nutritionFacts.length > 0 && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container mobile={12} marginTop={4} spacing={0}>
            <Grid container mobile={3}>
              <Typography variant="h4">Serving Size</Typography>
            </Grid>
            <Grid mobile={9}>
              {editableServingSizes && editableServingSizes.length > 0 && (
                <StyledFormContainer>
                  <Grid container mobile={12} spacing={0}>
                    {editableServingSizes.map((servingSize, index) => (
                      <StyledServingSizeField
                        container
                        mobile={12}
                        key={index}
                        sx={{ mb: 2, alignItems: "flex-start" }}
                      >
                        <Grid mobile={3}>
                          <Typography variant="normalBold">
                            {servingSize.nutrientName}:
                          </Typography>
                        </Grid>
                        <Grid>
                          <Controller
                            name={`servingSizes.${index}.nutrientValue`}
                            control={control}
                            defaultValue={servingSize.nutrientValue}
                            render={({ field }) => (
                              <TextField
                                disabled={!!isReaderRole || !editable}
                                size="small"
                                {...register(
                                  `servingSizes.${index}.nutrientValue`,
                                  {
                                    required: "Value is required.",
                                  }
                                )}
                                error={Boolean(
                                  errors.servingSizes?.[index]?.nutrientValue
                                )}
                                helperText={
                                  errors.servingSizes?.[index]?.nutrientValue
                                    ?.message
                                }
                                onFocus={() => {
                                  setValue(
                                    `servingSizes.${index}.nutrientValue`,
                                    standardizedNumStringOrNA(
                                      getValues(
                                        `servingSizes.${index}.nutrientValue`
                                      ),
                                      selectedCountry!
                                    )
                                  );
                                }}
                                onBlur={(e) => {
                                  setValue(
                                    `servingSizes.${index}.nutrientValue`,
                                    localizedNumStringOrNA(
                                      e.target.value,
                                      selectedCountry!
                                    )
                                  );
                                  field.onBlur();
                                }}
                                sx={{ width: 124 }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid>
                          <TextField
                            disabled={!!isReaderRole || !editable}
                            fullWidth
                            size="small"
                            label="Units"
                            {...register(`servingSizes.${index}.uomId`, {
                              required: "Unit is required.",
                            })}
                            select
                            SelectProps={{ native: true }}
                            onChange={(e) => handleUomChange(index, e)}
                          >
                            {uomOptions.map((option) => (
                              <option key={option.uomName} value={option.uomId}>
                                {option.uomName}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      </StyledServingSizeField>
                    ))}
                  </Grid>
                </StyledFormContainer>
              )}
              {editableServingSizes.length === 0 && (
                <StyledEmptyBox>
                  <Typography variant="h6">{`No serving size information to display`}</Typography>
                </StyledEmptyBox>
              )}
            </Grid>
            <FortifiedItem
              {...{
                selectedLanguage,
              }}
            />
          </Grid>
          <Grid container mobile={12} sx={{ mb: 2 }}>
            <Typography variant="h4">Nutrition Information</Typography>
          </Grid>
          <Grid container spacing={2} aria-label="Nutrient Fields">
            {nutritionFacts.map(
              (nutritionFact: ValidNutritionFact, index: number) => (
                <Grid
                  container
                  mobile={6}
                  marginBottom={1}
                  role="group"
                  aria-label={`Nutrient ${index + 1}`}
                  key={index}
                >
                  <StyledNutrientFactHeader mobile={3}>
                    <Typography variant="h6" sx={{ wordBreak: "break-word" }}>
                      {nutritionFact.nutrientFactName!.charAt(0).toUpperCase() +
                        nutritionFact.nutrientFactName!.slice(1)}
                    </Typography>
                  </StyledNutrientFactHeader>
                  <StyledNutrientFactSection mobile={3}>
                    <Controller
                      name={`nutrients.${index}.nutrientValue`}
                      control={control}
                      defaultValue={nutritionFact.nutrientValue}
                      render={({ field }) => (
                        <StyledTextField
                          label={nutritionFact.uomName}
                          disabled={!!isReaderRole || !editable}
                          {...field}
                          InputLabelProps={{ shrink: true }}
                          onFocus={() => {
                            setValue(
                              `nutrients.${index}.nutrientValue`,
                              standardizedNumStringOrNA(
                                getValues(`nutrients.${index}.nutrientValue`),
                                selectedCountry!

                              )
                            );
                          }}
                          onBlur={(e) => {
                            setValue(
                              `nutrients.${index}.nutrientValue`,
                              localizedNumStringOrNA(
                                e.target.value,
                                selectedCountry!
                              )
                            );
                            field.onBlur();
                          }}
                        />
                      )}
                    />
                  </StyledNutrientFactSection>
                  <StyledNutrientFactSection mobile={3}>
                    <Controller
                      name={`nutrients.${index}.dvValue`}
                      control={control}
                      defaultValue={nutritionFact.nutrientValue}
                      render={({ field }) => (
                        <StyledTextField
                          label="%div"
                          disabled={!!isReaderRole || !editable}
                          {...field}
                          InputLabelProps={{ shrink: true }}
                          onFocus={() => {
                            setValue(
                              `nutrients.${index}.dvValue`,
                              standardizedNumStringOrNA(
                                getValues(`nutrients.${index}.dvValue`),
                                selectedCountry!

                              )
                            );
                          }}
                          onBlur={(e) => {
                            setValue(
                              `nutrients.${index}.dvValue`,
                              localizedNumStringOrNA(
                                e.target.value,
                                selectedCountry!
                              )
                            );
                            field.onBlur();
                          }}
                        />
                      )}
                    />
                  </StyledNutrientFactSection>
                  <StyledNutrientFactSection mobile={3}>
                    <Controller
                      name={`nutrients.${index}.hundredGramPerProduct`}
                      control={control}
                      defaultValue={nutritionFact.nutrientValue}
                      render={({ field }) => (
                        <StyledTextField
                          label="per 100g"
                          disabled={!!isReaderRole || !editable}
                          {...field}
                          InputLabelProps={{ shrink: true }}
                          onFocus={() => {
                            setValue(
                              `nutrients.${index}.hundredGramPerProduct`,
                              standardizedNumStringOrNA(
                                getValues(
                                  `nutrients.${index}.hundredGramPerProduct`
                                ),
                                selectedCountry!

                              )
                            );
                          }}
                          onBlur={(e) => {
                            setValue(
                              `nutrients.${index}.hundredGramPerProduct`,
                              localizedNumStringOrNA(
                                e.target.value,
                                selectedCountry!
                              )
                            );
                            field.onBlur();
                          }}
                        />
                      )}
                    />
                  </StyledNutrientFactSection>
                </Grid>
              )
            )}
          </Grid>
          {!isReaderRole && editable && (
            <Grid
              mobile={12}
              sx={{
                p: 0,
                my: 4,
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <StyledSecondaryButton
                variant="contained"
                size="large"
                aria-label="Reset"
                disabled={!isDirty}
                onClick={() => reset()}
              >
                Reset
              </StyledSecondaryButton>
              <StyledSubmitButton
                type="submit"
                variant="contained"
                size="large"
              >
                Submit
              </StyledSubmitButton>
            </Grid>
          )}
        </form>
      )}
      {nutritionFacts.length === 0 && (
        <Grid mobile={9} mobileOffset={3} marginTop={4}>
          <StyledEmptyBox>
            <Typography variant="h6">No nutrition facts to display</Typography>
          </StyledEmptyBox>
        </Grid>
      )}
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          const formValues = getValues();
          onSubmit(formValues);
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
    </>
  );
};

type ProductsAndItemsCheckboxSelectorProps = {
  selectableProducts: SelectableItemOrProduct[];
  selectableOptionalItems: SelectableItemOrProduct[];
  selectedProductAndItemIds: number[];
  onChange: (id: number, isChecked: boolean) => void;
};
const ProductsAndItemsCheckboxSelector = ({
  selectableProducts,
  selectableOptionalItems,
  selectedProductAndItemIds,
  onChange,
}: ProductsAndItemsCheckboxSelectorProps) => {
  return (
    <>
      <Typography variant="h4" marginTop={2} marginBottom={2}>
        Products
      </Typography>
      {selectableProducts.map((product) => (
        <Grid key={product.id}>
          <FormControlLabel
            key={product.id}
            control={
              <Checkbox
                disabled={false}
                checked={selectedProductAndItemIds.includes(product.id)}
                onChange={(e) => onChange(product.id, e.target.checked)}
              />
            }
            label={product.name}
          />
        </Grid>
      ))}
      {selectableOptionalItems.length > 0 && (
        <>
          <Typography variant="h4" marginTop={2} marginBottom={2}>
            Optional Items
          </Typography>
          {selectableOptionalItems.map((optionalItem) => (
            <Grid key={optionalItem.id}>
              <FormControlLabel
                key={optionalItem.id}
                control={
                  <Checkbox
                    disabled={false}
                    checked={selectedProductAndItemIds.includes(
                      optionalItem.id
                    )}
                    onChange={(e) =>
                      onChange(optionalItem.id, e.target.checked)
                    }
                  />
                }
                label={optionalItem.name}
              />
            </Grid>
          ))}
        </>
      )}
    </>
  );
};

type MutexRadioSelectorProps = {
  selectableMutexGroups: SelectableMutexGroup[];
  selectedMutexOptions: {
    [key: number]: number;
  };
  onChange: (groupId: number, elementId: number) => void;
};
const MutexRadioSelector = ({
  selectableMutexGroups,
  selectedMutexOptions,
  onChange,
}: MutexRadioSelectorProps) => {
  // Extract the IDs from selectableMutexGroups
  return (
    <>
      {selectableMutexGroups.length > 0 && (
        <>
          <Typography variant="h4" marginTop={2} marginBottom={2}>
            Mutex Groups
          </Typography>
          {selectableMutexGroups.map((mutexGroup) => (
            <Grid mobile={12}>
              <FormControl>
                <FormLabel
                  id={`mutex-${mutexGroup.id}-select-option-radio-group`}
                >
                  <Typography variant="h6">{mutexGroup.name}</Typography>
                </FormLabel>
                <StyledRadioGroup
                  aria-labelledby={`mutex-${mutexGroup.id}-select-option-radio-group`}
                  name={`mutex-${mutexGroup.id}-selected-option`}
                  value={selectedMutexOptions[mutexGroup.id] || 0}
                  onChange={(e) =>
                    onChange(mutexGroup.id, Number(e.target.value))
                  }
                  data-testid="mutex-selected-option-radio-group"
                >
                  {mutexGroup.options.map((mutexOption) => (
                    <FormControlLabel
                      value={mutexOption.elementId}
                      control={<Radio />}
                      label={mutexOption.elementName}
                    />
                  ))}
                </StyledRadioGroup>
              </FormControl>
            </Grid>
          ))}
        </>
      )}
    </>
  );
};

type NutritionFactsReadOnlyProps = {
  selectedLanguage: string | null;
  selectableProductsAndItems: SelectableItemOrProduct[];
  selectedElementIds: number[];
  selectableMutexGroups: SelectableMutexGroup[];
  selectedMutexOptions: {
    [key: number]: number;
  };
};
const NutritionFactsReadOnly = ({
  selectedLanguage,
  selectableProductsAndItems,
  selectedElementIds,
  selectableMutexGroups,
  selectedMutexOptions,
}: NutritionFactsReadOnlyProps) => {
  const { itemId } = useItem();

  const { selectedCountry } = useContext(RoleContext);

  const { apiClient } = useAxios();

  const nutritionFactsQuery = useCustomQuery(
    [
      "getNutritionFactsByProductIds",
      { itemId, productIds: selectedElementIds, selectedLanguage },
    ],
    () =>
      getNutritionFactsByProductIds(apiClient)({
        itemId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
        ...(selectedElementIds.length > 0
          ? { productCombinationId: selectedElementIds.join(",")! }
          : {}),
      })
  );

  const nutritionFacts = useMemo(() => {
    if (nutritionFactsQuery.data?.data.nutrientFacts === undefined) {
      return [];
    }
    const validNutritionFacts: ValidNutritionFact[] =
      formatAndFilterNutritionFacts(
        nutritionFactsQuery.data?.data.nutrientFacts,
        false
      );
    return validNutritionFacts.map((nutritionFact) => ({
      ...nutritionFact,
      nutrientValue: localizedNumStringOrNA(
        nutritionFact.nutrientValue,
        selectedCountry!
      ),
      dvValue: localizedNumStringOrNA(nutritionFact.dvValue, selectedCountry!),
      hundredGramPerProduct: localizedNumStringOrNA(
        nutritionFact.hundredGramPerProduct,
        selectedCountry!
      ),
    }));
  }, [nutritionFactsQuery.data, selectedCountry]);

  const ingredientsQuery = useCustomQuery(
    ["getIngredientsByItemAndProductIds", { itemId, selectedLanguage }],
    () =>
      getIngredientsByItemAndProductIds(apiClient)({
        itemId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
      })
  );

  const getProductNameById = useCallback(
    (productId: number): string | undefined => {
      const productOrItem = selectableProductsAndItems.find(
        (p) => p.id === productId
      );
      if (productOrItem) {
        return productOrItem.name;
      }
    },
    [selectableProductsAndItems]
  );

  const getSelectedMutexGroupItemName = useCallback(() => {
    const selectedmutexGroupAllOptions = [] as MutexOption[];

    selectableMutexGroups.forEach((item) => {
      item.options.forEach((i) => {
        selectedmutexGroupAllOptions.push(i);
      });
    });

    const elementName = selectedmutexGroupAllOptions
      ?.filter((item) =>
        Object.values(selectedMutexOptions)?.includes(item.elementId)
      )
      ?.map((item) => item.elementName);
    return elementName;
  }, [selectableMutexGroups, selectedMutexOptions]);

  const selectedProductNames: string[] = useMemo(
    () =>
      selectedElementIds
        .map((productId) => getProductNameById(productId))
        .filter(
          (maybeProductName): maybeProductName is string =>
            maybeProductName !== undefined
        ),
    [getProductNameById, selectedElementIds]
  );
  const selectedIngredients = useMemo(() => {
    const selectedMutexGroupIdNames = getSelectedMutexGroupItemName();
    if (ingredientsQuery.data?.data.ingredients === undefined) {
      return [];
    }
    const formattedIngredients = ingredientsQuery.data?.data.ingredients
      .map(formatProductIngredient)
      .filter((ingredient) => {
        return (
          selectedProductNames.includes(ingredient.productName) ||
          selectedMutexGroupIdNames?.includes(ingredient.productName)
        );
      });
    return formattedIngredients;
  }, [
    ingredientsQuery.data,
    selectedProductNames,
    getSelectedMutexGroupItemName,
  ]);

  const getRow = React.useCallback(
    (index: number) => {
      return (
        <>
          <Grid mobile={4.5}>
            <Typography sx={{ ml: 2, pl: 0 }} variant={"h6"}>
              --
            </Typography>
          </Grid>
          <Grid mobile={2.5}>
            <Typography variant={"body1"}>--</Typography>
          </Grid>
          <Grid mobile={2.5}>
            <Typography variant={"body1"}>--</Typography>
          </Grid>
          <Grid
            mobile={2.5}
            sx={{
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Typography sx={{ mr: 2 }} variant="h6">
              --
            </Typography>
          </Grid>
          {nutritionFacts.length !== index + 1 && <StyledBlackLine />}
        </>
      );
    },
    [nutritionFacts.length]
  );

  return (
    <>
      <Typography variant="h2">Nutrition Facts</Typography>
      <StyledCardNoRadius>
        <Grid container>
          <StyledBlackBar />
          <Grid mobile={2.5} mobileOffset={4.5}>
            <Typography variant="h6">Per 100g product</Typography>
          </Grid>
          <Grid mobile={2.5}>
            <Typography variant="h6">Per Serving</Typography>
          </Grid>
          <Grid mobile={2.5}>
            <StyledNoWrapLabel variant="h6">% Daily Value %</StyledNoWrapLabel>
          </Grid>
          <StyledBlackBarShort />
          {nutritionFactsQuery.isFetching && <Loader />}
          <>
            {nutritionFacts.length > 0 ? (
              nutritionFacts.map((item, index) => (
                <>
                  <Grid mobile={4.5}>
                    <Typography sx={{ ml: 2, pl: 0 }} variant={"h6"}>
                      {item.nutrientFactName}
                    </Typography>
                  </Grid>
                  <Grid mobile={2.5}>
                    <Typography variant={"body1"}>
                      {item.hundredGramPerProduct}
                    </Typography>
                  </Grid>
                  <Grid mobile={2.5}>
                    <Typography variant={"body1"}>
                      {formattedNutrientOrNA(item.nutrientValue, item.uomName)}
                    </Typography>
                  </Grid>

                  <Grid
                    mobile={2.5}
                    sx={{
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <Typography sx={{ mr: 2 }} variant="h6">
                      {formattedNutrientOrNA(item.dvValue, "%")}
                    </Typography>
                  </Grid>
                  {nutritionFacts.length !== index + 1 && <StyledBlackLine />}
                </>
              ))
            ) : (
              <>
                {Array.from({ length: 8 }, (_, i) => i).map((i) =>
                  getRow(8 + i)
                )}
              </>
            )}
          </>
        </Grid>
      </StyledCardNoRadius>
      <StyledTypographyWithMargin variant="h2">
        Ingredients/Components
      </StyledTypographyWithMargin>
      {selectedIngredients.map((ingredient: ProductIngredient) => (
        <StyledIngredientBox>
          <StyledTypographyWithMargin variant="h4">
            {ingredient.productName}
          </StyledTypographyWithMargin>
          <Typography variant="body1">{ingredient.ingredients}</Typography>
          {ingredient.contains.length > 0 && (
            <StyledTypographyWithMargin variant="h6">
              {ingredient.contains}
            </StyledTypographyWithMargin>
          )}

          {ingredient.productAdditionalText.length > 0 && (
            <Typography variant="body1">
              {ingredient.productAdditionalText}
            </Typography>
          )}
        </StyledIngredientBox>
      ))}
    </>
  );
};

export const NutritionInfo = () => {
  const navigate = useNavigate();

  const { apiClient } = useAxios();
  const { itemId, isLive } = useItem();

  const { defaultLanguage, selectedCountry } = useContext(RoleContext);


  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(
    defaultLanguage
  );
  const [selectableProducts, setSelectableProducts] = useState<
    SelectableItemOrProduct[] | null
  >(null);
  const [selectableOptionalItems, setSelectableOptionalItems] = useState<
    SelectableItemOrProduct[] | null
  >(null);
  const [selectableMutexGroups, setSelectableMutexGroups] = useState<
    SelectableMutexGroup[] | null
  >(null);
  const [selectedProductsAndItems, setSelectedProductsAndItems] = useState<
    number[]
  >([]);
  const [selectedMutexOptions, setSelectedMutexOptions] = useState<{
    [key: number]: number;
  } | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const selectedIds = useMemo(() => {
    if (selectedProductsAndItems !== null && selectedMutexOptions !== null) {
      // selectedIds denotes the current combination of Products/Optional Items/Mutex selections
      // Filter out any zero ("None") values, and eliminate any duplicate values via Set()
      return [
        ...new Set([
          ...selectedProductsAndItems,
          ...Object.values(selectedMutexOptions).filter((val) => val > 0),
        ]),
      ];
    }
    return [];
  }, [selectedProductsAndItems, selectedMutexOptions]);

  const uomQuery = useCustomQuery(["getUomDetails", { itemId }], () =>
    getUomDetails(apiClient)({
      itemId,
      countryCode: selectedCountry!,
      languageCode: selectedLanguage!,
    })
  );

  const primaryUomOptions: UomData[] = useMemo(() => {
    let primaryOptions: UomData[] = [];
    if (typeof uomQuery.data?.data.servingSize !== "undefined") {
      primaryOptions = uomQuery.data.data.servingSize.filter(
        (maybeUom): maybeUom is UomData => isUom(maybeUom) === true
      );
    } else if (typeof uomQuery.data?.data.primaryServingSize !== "undefined") {
      primaryOptions = uomQuery.data.data.primaryServingSize.filter(
        (maybeUom): maybeUom is UomData => isUom(maybeUom) === true
      );
    }
    return primaryOptions;
  }, [uomQuery.data]);

  const secondaryUomOptions = useMemo(() => {
    if (typeof uomQuery.data?.data.secondaryServingSize !== "undefined") {
      return uomQuery.data.data.secondaryServingSize.filter(
        (maybeUom): maybeUom is UomData => isUom(maybeUom) === true
      );
    }
    return [];
  }, [uomQuery.data]);

  const uomOptions = useMemo(() => {
    return [...primaryUomOptions, ...secondaryUomOptions];
  }, [primaryUomOptions, secondaryUomOptions]);

  const servingSizeQuery = useCustomQuery(
    ["getItemServingSize", { itemId, selectedLanguage, selectedIds }],
    () =>
      getItemServingSize(apiClient)({
        itemId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
        productCombinationId: selectedIds.join(","),
      }),
    uomQuery.isSuccess === true
  );
  const servingSizes: ServingSizeNutritionData[] = useMemo(() => {
    if (servingSizeQuery.data?.data.servingList === undefined) {
      return [];
    }
    return servingSizeQuery.data?.data.servingList
      .map((servingSize) => formatServingSize(servingSize, selectedCountry!))
      .filter(isDefined);
  }, [servingSizeQuery.data, selectedCountry]);

  const recalculateEnableLatest: Boolean = useMemo(() => {
    if (servingSizeQuery.data?.data.isRecalculateEnable === "N") {
      return true;
    } else {
      return false;
    }
  }, [servingSizeQuery.data]);

  const mappedElementsQuery = useCustomQuery(
    ["getSelectedElementsMapping", { itemId, selectedLanguage }],
    () =>
      getSelectedElementsMapping(apiClient)({
        itemId: String(itemId),
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
      })
  );

  const mappedElements = useMemo(() => {
    if (mappedElementsQuery.data?.data.elementList === undefined) {
      return [];
    }
    const validMappedElements =
      mappedElementsQuery.data?.data.elementList?.filter(
        (
          maybeMappedElement
        ): maybeMappedElement is ValidSelectedElementMapping => {
          return isElementMapping(maybeMappedElement) === true;
        }
      );
    return validMappedElements;
  }, [mappedElementsQuery.data]);

  const productsByItemIdQuery = useCustomQuery(
    [
      "getProductsByItemID",
      { countryCode: selectedCountry, itemId, selectedLanguage },
    ],
    () =>
      getProductsByItemId(apiClient)({
        itemId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
      }),
    mappedElements.length > 0
  );

  useEffect(() => {
    if (
      productsByItemIdQuery.isSuccess &&
      mappedElementsQuery.isSuccess &&
      typeof productsByItemIdQuery.data?.data !== "undefined"
    ) {
      const {
        products = [],
        optionalItems = [],
        mutexGroup: mutexOptions = [],
      } = productsByItemIdQuery.data.data;

      const formattedMutexOptions: MutexOption[] = mutexOptions
        .map(formatMutexOption)
        .filter(isDefined);
      const mappedMutexGroups = mappedElements.filter((element) => {
        return element.elementType === "Mutex";
      });
      const formattedMutexGroups: SelectableMutexGroup[] =
        mappedMutexGroups.map((mutexGroup) => {
          return {
            id: mutexGroup.elementId,
            name: mutexGroup.elementName,
            options: formattedMutexOptions.filter(
              (mutexOption) => mutexOption.mutexId === mutexGroup.elementId
            ),
          };
        });
      const mutexGroupIds = formattedMutexGroups.map((group) => group.id);
      const formattedProducts: SelectableItemOrProduct[] = products
        .map(formatSelectableProduct)
        .filter(isDefined)
        .filter((product) => !mutexGroupIds.includes(product.id));
      const formattedOptionalItems: SelectableItemOrProduct[] = optionalItems
        .map(formatSelectableItem)
        .filter(isDefined)
        .filter((optionalItem) => !mutexGroupIds.includes(optionalItem.id));

      setSelectableProducts(formattedProducts);
      setSelectableOptionalItems(formattedOptionalItems);
      setSelectableMutexGroups(formattedMutexGroups);
      // all products are initially selected
      setSelectedProductsAndItems(formattedProducts.map((p) => p.id));
      setSelectedMutexOptions(
        formattedMutexGroups.reduce<{ [key: number]: number }>((acc, group) => {
          if (group.options.length > 0) {
            const defaultValue = group.options.find((item) => {
              return item.isDefault === "Y";
            });
            acc[group.id] = defaultValue
              ? defaultValue.elementId
              : group.options[0].elementId;
          }
          return acc;
        }, {})
      );
    }
  }, [
    productsByItemIdQuery.isSuccess,
    productsByItemIdQuery.data,
    mappedElementsQuery.isSuccess,
    mappedElements,
  ]);

  const handleCheckboxChange = useCallback((id: number, isChecked: boolean) => {
    setSelectedProductsAndItems((prev) =>
      isChecked ? [...prev, id] : prev.filter((prevId) => prevId !== id)
    );
  }, []);

  const handleRadioChange = useCallback(
    (groupId: number, elementId: number) => {
      setSelectedMutexOptions((prev) => ({
        ...prev,
        [groupId]: elementId,
      }));
    },
    []
  );

  const mappingsLoaded = useMemo(
    () =>
      selectableProducts !== null &&
      selectableOptionalItems !== null &&
      selectableMutexGroups !== null,
    [selectableProducts, selectableOptionalItems, selectableMutexGroups]
  );

  return (
    <StyledRelativeContainer>
      <Grid container mobile={12} spacing={0}>
        <StyledHeaderBar mobile={12} container>
          <Typography variant="h2">Nutrition Information</Typography>
          <StyledSecondaryButton
            variant="contained"
            disabled={!isLive}
            onClick={() => {
              navigate(`/items/${itemId}/compare-with-live`);
            }}
          >
            Compare with live
          </StyledSecondaryButton>
        </StyledHeaderBar>
        <Grid container mobile={12} sx={{ mb: 2, justifyContent: "flex-end" }}>
          <LanguageSelector
            selectedLanguage={selectedLanguage!}
            onChange={(language) => setSelectedLanguage(language)}
          />
        </Grid>
        {servingSizes && uomOptions && (
          <>
            {mappedElements.length > 0 ? (
              <Grid container mobile={12} marginTop={4} spacing={0}>
                <Grid container mobile={3} flexDirection="column">
                  {selectableProducts !== null &&
                    selectableOptionalItems !== null && (
                      <ProductsAndItemsCheckboxSelector
                        selectableProducts={selectableProducts}
                        selectableOptionalItems={selectableOptionalItems}
                        selectedProductAndItemIds={selectedProductsAndItems}
                        onChange={handleCheckboxChange}
                      />
                    )}
                  {selectableMutexGroups !== null &&
                    selectedMutexOptions !== null && (
                      <MutexRadioSelector
                        selectableMutexGroups={selectableMutexGroups}
                        selectedMutexOptions={selectedMutexOptions}
                        onChange={handleRadioChange}
                      />
                    )}
                </Grid>
                <Grid mobile={9}>
                  <ServingSizesOnlyForm
                    servingSizes={servingSizes}
                    uomOptions={uomOptions}
                    selectedLanguage={selectedLanguage}
                    recalculateEnableLatest={recalculateEnableLatest}
                    isFetching={
                      servingSizeQuery.isFetching || uomQuery.isFetching
                    }
                  />
                  {mappingsLoaded && (
                    <Grid marginTop={4}>
                      <NutritionFactsReadOnly
                        selectedLanguage={selectedLanguage}
                        selectableProductsAndItems={[
                          ...selectableProducts!,
                          ...selectableOptionalItems!,
                        ]}
                        selectedElementIds={selectedIds}
                        selectableMutexGroups={selectableMutexGroups!}
                        selectedMutexOptions={selectedMutexOptions!}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : (
              <>
                <AllNutritionFacts
                  servingSizes={servingSizes}
                  uomOptions={uomOptions}
                  onSuccess={(message) => setSuccessMessage(message)}
                  onError={(error) => setErrorMessage(error)}
                  selectedLanguage={selectedLanguage}
                />
              </>
            )}
          </>
        )}
      </Grid>
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
};
const StyledNutrientFactHeader = styled(Grid)({
  display: "flex",
  alignItems: "center",
});
const StyledNoWrapLabel = styled(Typography)({
  textAlign: "end",
  paddingRight: 16,
  whiteSpace: "nowrap",
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledRelativeContainerShort = styled(Grid)({
  margin: 0,
  position: "relative",
  minHeight: 200,
});
const StyledTextField = styled(TextField)(({ theme }) => ({
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
}));
const StyledHeaderBar = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  marginLeft: 0,
  marginRight: 0,
}));
const StyledRadioGroup = styled(RadioGroup)({
  paddingLeft: "20px",
});
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledBlackBar = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: 20,
  backgroundColor: "black",
  marginBottom: theme.spacing(2),
}));
const StyledBlackBarShort = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: 8,
  backgroundColor: "black",
  marginBottom: theme.spacing(1),
}));
const StyledBlackLine = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: 1,
  backgroundColor: "black",
  marginTop: 3,
}));
const StyledCardNoRadius = styled(Card)({
  borderRadius: 0,
});
const StyledTypographyWithMargin = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledFormContainer = styled(Box)({
  maxWidth: 700,
});
const StyledServingSizeField = styled(Grid)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));
const StyledSpaceBetweenContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  justifyContent: "space-between",
}));
const StyledFlexEndContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  justifyContent: "flex-end",
}));
const StyledNutrientFactSection = styled(Grid)({
  justifyContent: "flex-end",
});
const StyledSubmitButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 88,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.light,
}));
const StyledIngredientBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  borderBottom: "2px solid #F6F6F6",
}));