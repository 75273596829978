import { components } from "../data/items-v2.types";
import { RequireFields } from "../utils";

export type FortifiedItemData = RequireFields<
  components["schemas"]["fortifiedNutrition"]
>;

export function isFortifiedItem(
  maybeFortifiedItem: any
): maybeFortifiedItem is FortifiedItemData {
  const { nutrientFactId, display, name } = maybeFortifiedItem;
  return (
    typeof nutrientFactId === "number" &&
    typeof display === "string" &&
    typeof name === "string"
  );
}
